import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Grid, Typography, CardMedia, CardContent, Card } from '@material-ui/core';

const styles = theme => ({
  page: {
    overflow: 'hidden',
  },
  pictureContainer: {
    padding: theme.spacing.unit,
    textAlign: 'center',
  },
  textContainer: {
    padding: theme.spacing.unit,
    paddingBottom: theme.spacing.unit * 4,
  },
  picture: {
    maxWidth: '100%',
  },
  stats: {
    paddingTop: theme.spacing.unit * 2,
  }

});


class AboutMe extends Component {


  render() {

    const { classes } = this.props;

    return (
      <div className={classes.page}>
        <Grid container spacing={24} justify="center">
          <Grid item xs={12} sm={3}>
            <Card className={classes.card} justify="center" square={true}>
              <CardMedia
                component="img"
                alt="Sascha de Waal"
                className={classes.media}
                image="/media/aboutMe/self1.jpg"
                title="Sascha de Waal"
              />
              <CardContent>
                <Typography variant="h5">
                  Sascha de Waal
                </Typography>
                <Typography variant="subtitle2">
                  Programmer
                </Typography>
                <div className={classes.stats}>
                  <Grid container>
                    <Grid item xs={3}>
                      <Typography variant="body1">
                        Mail
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body1">
                        Info@Developersascha.nl
                      </Typography>
                    </Grid>

                    <Grid item xs={3}>
                      <Typography variant="body1">
                        LinkedIn
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body1">
                        in/sascha-de-waal-576716b1/
                      </Typography>
                    </Grid>
                  </Grid>
                </div>

              </CardContent>

            </Card>
          </Grid>
          <Grid item xs={12} sm={5}>
            <div className={classes.textContainer}>
              <Typography variant="h4" gutterBottom class='header'>A little bit about me</Typography>
              <Typography variant="body1" gutterBottom>
                Creating is the most powerful thing we can do as humans. We can create tools that make our life easier, we can create systems that makes our world fairer (laws). And we can create experiences that we can transfer to other people, we call those experiences games.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Games are the ultimate way of experience, something without any risk and for a relative low budget. It is a way to let other people have fun or a way to tell a story and make the player feel like they are part of it. Games also makes it possible to learn new things that in real life are a bit harder.
              </Typography>
              <Typography variant="body1" gutterBottom>
                It is really strange; we make a set of rules, with some nice art and music, and we as humans like to follow it and we have fun doing so. While there is not a big reason to do so, I mean, we won't die if we don’t follow it. Some rule sets we like, others we don’t. It is trial-and-error most of the time. But that makes it really interesting.
              </Typography>
              <Typography variant="body1" gutterBottom>
                A big part of creating games is also research. You need to be able to keep learning, explore new technology and always be open for new experiences. This is what I like most about this profession. You keep exploring the possibilities and keep creating nice things with new learned stuff.
              </Typography>
            </div>
            <div className={classes.textContainer}>
              <Typography variant="h4" gutterBottom class='header'>How it started</Typography>
              <img src='/media/aboutMe/firstGame.jpg' className={classes.picture} alt='First game' />
              <Typography variant="body1" gutterBottom>
                When I was 14 years old, my junior high school (vmbo) in the city of Deventer thought it would be a good idea to let the children explore their own talents. So, they started a program in which we could follow several creative courses on various topics like music, dance, and creating DGames. This was the first time I came in contact with the development of games.
              </Typography>
              <Typography variant="body1" gutterBottom>
                My first game was 'Tic Tac Toe' created with the engine BlitzMax. Not very special, but it was my first game that I created, and I liked this so much, that even when this course was over, I kept creating games in my spare time.
              </Typography>
            </div>
            <div className={classes.textContainer}>
              <Typography variant="h4" gutterBottom class='header'>Grafisch Lyceum Utrecht</Typography>
              <img src='/media/aboutMe/glu.jpg' className={classes.picture} alt='GLU' />
              <Typography variant="body1" gutterBottom>
                After my junior high school (vmbo), I started a Game Development education on the Grafisch Lyceum Utrecht (GLU). Here, I learned more advanced programming like design patterns or good OOP structures. Most of the education was about the technical side of games. As a part of the education I did an internship at the game company PixelPixies. For 1 year I was working on both commercially and internal projects. At the same time, I also started to work as programmer at Virtask. A company creating Anne, an Avatar system that is designed to help the elderly people.
              </Typography>
            </div>
            <div className={classes.textContainer}>
              <Typography variant="h4" gutterBottom class='header'>University of Art Utrecht (HKU)</Typography>
              <img src='/media/aboutMe/hku.jpg' className={classes.picture} alt='hku' />
              <Typography variant="body1" gutterBottom>
                After I got my MBO-diploma, I thought I wasn’t ready for the industry. My technical skills were good enough, but I realized that pure technique is worthless if it doesn't affect the end user. And at that point my design skills where limited. I wanted to improve my design skills. So, I applied to the University of Art Utrecht (HKU), to learn more about design and communication skills.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Now that I am almost at the end of my education, I have to say I am really happy with this decision. I remained a programmer and know a lot more and that is always better.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(AboutMe);
