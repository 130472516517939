import React, { Component } from 'react';
import { Grid, Typography, Card, CardMedia, CardContent, CardActions, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Experience from './components/Experience/Experience';
import Skill from './components/Skill/Skill';
import TableHead from "@material-ui/core/es/TableHead/TableHead";
import TableBody from "@material-ui/core/es/TableBody/TableBody";
import TableRow from "@material-ui/core/es/TableRow/TableRow";
import TableCell from "@material-ui/core/es/TableCell/TableCell";
import Table from "@material-ui/core/es/Table/Table";

const styles = theme => ({
  page: {
    overflow: 'hidden',
  },
  card: {
    width: 'inherit',
  },
  cardContainer: {
    width: '100%',
    marginBottom: theme.spacing.unit * 3,
  },
  pictureMedia: {
    objectFit: 'cover',
    transform: 'scale(-1, 1)',
  },
  media: {
    objectFit: 'cover',
    transform: 'scale(1, 1)',
  },

  experienceList: {
    marginLeft: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },

  personalContainer: {
    paddingTop: theme.spacing.unit * 2,
  },
  personalTable: {
    paddingTop: 0,
    marginTop: 0,
  },
  personalCellTitle: {
    paddingLeft: '2px',
  }
});

const work = [
  {
    title: 'ROVR',
    subtitle: 'Spatial design tool',
    image: '/media/screenshots/rovr/0.png',
  },
  {
    title: 'EHL Tribus configurator',
    subtitle: 'Web configurator for designing and calculating your backyard with the Tribus tiles',
    image: '/media/screenshots/tribus/0.png',
  },
  {
    title: 'Computer play-testing',
    subtitle: 'Framework that play-test the game balance',
    image: '/media/screenshots/playtesting/5.jpg',
  },
  {
    title: 'Blaze Revolution',
    subtitle: 'Game made by Little Chicken',
    image: '/media/screenshots/chicken/0.png',
  },
  {
    title: 'The Seminary of Sight',
    subtitle: 'Stroy driven cosmic horror game',
    image: '/media/screenshots/theSeminaryOfSight/0.png',
  }
];

class Resume extends Component {
  state = {
    scroll: 0,
  };


  //3
  //5
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.page}>
        <Grid container spacing={24} justify="center">
          <Grid item xs={12} sm={3}>
            <div className={classes.cardContainer}>
              <Card className={classes.card} justify="center" square={true}>
                <CardMedia
                  component="img"
                  alt="Sascha de Waal"
                  className={classes.pictureMedia}
                  image="/media/aboutMe/self2.jpg"
                  title="Sascha de Waal"
                />
                <CardContent>
                  <Typography variant="h5">
                    Sascha de Waal
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom sx={{ paddingBottom: 3 }}>
                    Programmer
                  </Typography>
                  <Typography component="p">
                    The goal of technology is to broaden our capabilities, experience (impossible) things, and most importantly, make things more fun.
                    <br /><br />
                    But to do these things, we need reliable and easily maintainable software. That is our responsibility as programmers.
                  </Typography>

                  <div className={classes.personalContainer}>
                    <Typography variant="h6">
                      Personal data
                    </Typography>
                    <Table className={classes.personalTable}>
                      <TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row"
                              className={classes.personalCellTitle}>Name</TableCell>
                            <TableCell component="th" scope="row">Sascha de Waal</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row"
                              className={classes.personalCellTitle}>Birthday</TableCell>
                            <TableCell component="th" scope="row">15 april 1994</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row"
                              className={classes.personalCellTitle}>Mail</TableCell>
                            <TableCell component="th" scope="row">{('info' + '#' + 'developersascha' + '.nl').replace('#', '@')}</TableCell>
                          </TableRow>
                        </TableBody>
                      </TableHead>
                    </Table>
                  </div>
                </CardContent>
              </Card>
            </div>
            {work.map(e => (
              <div className={classes.cardContainer}>
                <Card className={classes.card} justify="center" square={true}>
                  <CardMedia
                    component="img"
                    alt={e.title}
                    className={classes.media}
                    image={e.image}
                    title={e.title}
                  />
                  <CardContent>
                    <Typography variant="h5">
                      {e.title}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      {e.subtitle}
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            ))}

          </Grid>
          <Grid item xs={12} sm={5}>

            <Typography variant="h5" class='header'>Programing skills points*</Typography>
            <div className={classes.experienceList}>
              <Skill percentage={100} name="Unity (C#)" />
              <Skill percentage={80} name="ReactJS" />
              <Skill percentage={80} name="Javascript" />
              <Skill percentage={60} name="TypeScript" />
              <Skill percentage={60} name="NodeJS" />

              <Skill percentage={59} name="ASP.NET (C#) " />
              <Skill percentage={50} name="Entity Framework" />

              <Skill percentage={30} name="CI/CD" />
              <Skill percentage={20} name="AI (Tensorflow)" />

              <Typography variant="body1" gutterBottom>
                <b>Also played with with:</b> Auth0, PlayCanvas, Flash (long time ago), Arduino, Raspberry pi, C++, Python, Unreal engine, Firebase, Bootstraps, Angular
              </Typography>
              <br />
              <Typography variant="caption">
                *My best skill receives 100 points, the rest is compared to that. Please note I am always very happy to learn any new technology. This is not the limit!
              </Typography>
            </div>

            <Typography variant="h5" class='header'>Relevant work experience</Typography>
            <div className={classes.experienceList}>
              <Experience
                title="Unity Developer & Web Developer @ Uil VR Solutions B.V. (VR Owl)"
                subTitle="2019 - Current"
                text="Started as a company that made custom cardboard VR glasses, but now are an experienced VR and AR Agency. I am working as a VR Unity developer. Mostly on ROVR, a product for spatial design and communication."
              />
              <Experience
                title="Web Development (React) @ Virtask B.V."
                subTitle="2015 - 2019"
                text="Virtask, or Anne4Care, is a company that creates virtual assistants, for elderly of disabled people, so they can life longer independently. I have been working on different projects as a (ReactJS) programmer and UI designer."
              />
              <Experience
                title="Game Programmer @ Little Chicken Game Company B.V."
                subTitle="2018"
                text="After my internship, I had a few months off before I went back to school. During this time, I have been working at Little chicken as a paid job
2017"
              />
              <Experience
                title="Internship Game Programmer @ Little Chicken Game Company B.V."
                subTitle="2017"
                text="Little Chicken is a game studio that creates entertainment games. I have been working as a Gameplay programmer in Unity 4 on an upcoming management/strategy game."
              />
              <Experience
                title="Internship Game Programmer @ Pixel Pixies"
                subTitle="2014 - 2015"
                text="I have been working on porting an old game called 'Villa Eeckhoorn' from Flash to Unity 3. And I have been working on a mobile app called The Combi Shaker."
              />
            </div>
            <Typography variant="h5" class='header'>Educations and certifications</Typography>
            <div className={classes.experienceList}>
              <Experience
                title="Driver licence B"
                subTitle="2022"
                text="European driver licence for passenger cars below 3500 KG"
              />
              <Experience
                title="Creative Media and Game Technologies HBO @ University of the Arts Utrecht (HKU)"
                subTitle="2015 - 2019"
                text="With nearly 4400 students, HKU is not only the largest University of Applied Sciences in the Netherlands but also one of the largest educational institutions in Europe. HKU has preparatory courses, bachelor's, postgraduate and master's degree programs in the following disciplines: design, theater, music, visual arts, games and interaction, art management, arts education, and media."
              />
              <Experience
                title="Game Development MBO-4 @ Grafisch Lyceum Utrecht"
                subTitle="2011 - 2015"
                text="The Grafisch Lyceum Utrecht, also known as GLU for short, is a Dutch vocational school in the city of Utrecht, with more than 100 years of history."
              />
            </div>


            <Typography variant="h5" class='header'>Other skill Points</Typography>
            <div className={classes.experienceList}>
              <Skill percentage={90} name="Git (gitflow)" />
              <Skill percentage={90} name="Deadlines" />
              <Skill percentage={70} name="Scrum" />
              <Skill percentage={80} name="3D printing" />
              <Skill percentage={60} name="Electronics" />
              <Skill percentage={70} name="Communication" />
              <Skill percentage={60} name="Fusion 360" />
              <Skill percentage={60} name="Firebase" />
              <Skill percentage={60} name="Unit Testing" />
              <Skill percentage={35} name="Azure" />

            </div>

            <Typography variant="h5" class='header'>Other experiences</Typography>
            <div className={classes.experienceList}>
              <Experience
                title="Stichting hoogvliegers (Volunteer work)"
                subTitle="2022 - Current"
                text="As entertainment for the children with my home made custom"
              />
              <Experience
                title="Selling Dutch tiles on markets"
                subTitle="2022 - current"
                text="My friend and I are designing and making (screen printing) dutch tiles about pop culture and anime. We sell these at Anime conventions."
              />
              <Experience
                title="Game Jams"
                subTitle="2012 and 2015"
                text="I participated the Global Game Jam for two times. In 2012 and in 2015. The first time with random people I met in Enschede, the second time with some classmates."
              />

              <Experience
                title="Scouting"
                subTitle=""
                text="I was a member of Westenenk in Deventer until I started my study in Utrecht"
              />
            </div>

            <Typography variant="h5" class='header'>Current freetime activities</Typography>
            <div className={classes.experienceList}>
              <Experience
                title="Physically crafting and electronics"
                subTitle=""
                text="Currently, I am working on a pinball machine. Most parts are made out of wood and 3d printed (like the flippers, and the lift system). I like to work with Arduino's, Pi's, and one of my two 3D printers."
              />
              <Experience
                title="Roleplay"
                subTitle=""
                text="Advanced Dungeons and Dragons, Pathfinder, I also make my costumes for fairs like Elfia and Castlefest."
              />
              <Experience
                title="Taking care of my ferret"
                subTitle=""
                text="A ferret named Furo joined my life in 2020. He is very affectionate, and I often play with him."
              />
              <Experience
                title="Hiking"
                subTitle=""
                text="Hiking in the woods with my friend (we live next to the woods). I also planned to go on a hiking holiday."
              />
              <Experience
                title="Canoeing"
                subTitle=""
                text="At the start of Corona, My friend and I bought canoes together. We have sailed several times in Utrecht, Amsterdam, and some nature spots. We even camped with the canoes"
              />
            </div>
          </Grid>
        </Grid>
      </div>

    );
  }
}

export default withStyles(styles)(Resume);
