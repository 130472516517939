import React, { Component } from 'react';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
import {withStyles} from "@material-ui/core/styles/index";

const styles = (theme) => ({
  container: {
    margin: 0,
    padding: 0,
    maxHeight: '400px',
    overflowY: 'scroll',
    background: '#f8f8f8',
  },
  pre: {
    margin: 0,
    padding: 0,
  }
});

class CodeBlock extends Component {

  constructor(props) {
    super(props);
    this.setRef = this.setRef.bind(this);
  }

  setRef(el) {
    this.codeEl = el;
  }

  componentDidMount() {
    this.highlightCode();
  }

  componentDidUpdate() {
    this.highlightCode();
  }

  highlightCode() {
    hljs.highlightBlock(this.codeEl);
  }

  render() {
    const { language, value, classes } = this.props;

    return (
      <div className={classes.container}>
        <pre className={classes.pre}>
          <code ref={this.setRef} className={`language-${language}`}>
            {value}
          </code>
        </pre>
      </div>
    )
  }
}
export default withStyles(styles)(CodeBlock);
