import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab, Toolbar, Typography, Grid, } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStream, faBook, faUser, faUserSecret } from '@fortawesome/free-solid-svg-icons'

import { getPageIndex, setPageIndex } from '../Helpers/RouterHelper';

import TimeLine from '../Timeline/TimeLine';
import Resume from '../Resume/Resume';
import AboutMe from '../AboutMe/AboutMe';

const styles = theme => ({
  tabContent: {
    marginTop: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
    textAlign: 'left',
  },
  center: {
    maxWidth: '2000px',
    width: '100%',
    position: 'absolute',
    left: '50vw',
  },
  content: {
    position: 'relative',
    left: '-50%',
  },
  title: {
    color: '#fff',
    marginTop: theme.spacing.unit * 3,
    textAlign: 'center',
    whiteSpace: 'nowrap',
  }
});


class HomePage extends Component {
  state = {
    tabIndex: 0,
    switching: true,
  };

  handleChange = (event, tabIndex) => {
    this.setState({ tabIndex, switching: true });
    setPageIndex(tabIndex);
    window.scrollTo(0, 0);
  };

  componentWillMount() {
    this.setState({ tabIndex: getPageIndex() });
  }

  render() {

    const { tabIndex, switching } = this.state;
    const { classes } = this.props;

    return (
      <div>
        <AppBar position="sticky">
          <Toolbar>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={1}>
                <Typography variant="h5" className={classes.title}>
                  <FontAwesomeIcon icon={faUserSecret} size="sm" /> Sascha de Waal
                </Typography>
              </Grid>
              <Grid item xs={false} sm={1}> </Grid>
              <Grid item xs={12} sm={8}>
                <Tabs value={tabIndex} onChange={this.handleChange} centered >
                  <Tab label="Work" icon={<FontAwesomeIcon icon={faStream} size="lg" />} />
                  <Tab label="Resume" icon={<FontAwesomeIcon icon={faBook} size="lg" />} />
                  <Tab label="About me" icon={<FontAwesomeIcon icon={faUser} size="lg" />} />
                </Tabs>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <div className={classes.tabContent}>
          <SwipeableViews
            axis={'x'}
            index={tabIndex}
            onSwitching={() => this.setState({ switching: true })}
            onChangeIndex={(i) => this.setState({ tabIndex: i })}
            onTransitionEnd={() => this.setState({ switching: false })}
          >
            {(tabIndex === 0 || switching) ? <TimeLine /> : <span />}
            {(tabIndex === 1 || switching) ? <Resume /> : <span />}
            {(tabIndex === 2 || switching) ? <AboutMe /> : <span />}
          </SwipeableViews>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(HomePage);
