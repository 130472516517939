import React, { Component } from 'react';
import { Button, Typography, Tooltip } from '@material-ui/core';
import {
    faBriefcase,
    faGamepad,
    faGraduationCap,
    faMemory,
    faSearch,
    faStar,
    faTools,
    faVrCardboard
} from "@fortawesome/free-solid-svg-icons/index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    buttonContainer: {
        marginTop: theme.spacing.unit,
        pointerEvents: 'auto',
    },
    timeLine: {
        height: '300px',
    },
    logoContainer: {
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        height: theme.spacing.unit * 4,
    },
    logo: {
        paddingRight: theme.spacing.unit * 2,
        opacity: 0.5,
    }
});

class TimelineElement extends Component {

    getIcon() {
        const { type } = this.props;

        switch (type) {
            case "game":
                return faGamepad;
            case "vr":
                return faVrCardboard;
            case "work":
                return faBriefcase;
            case "hardware":
                return faMemory;
            case "research":
                return faSearch;
            case "tutorial":
                return faGraduationCap;
            case "tool":
                return faTools;
            default:
                return faStar;
        }
    }

    render() {
        const {
            classes,
            name,
            description,
            picture,
            date,
            onClick,
            moreInformation,
            loadingReadMore,
            icons,
            links
        } = this.props;

        console.log(links);

        let buttons = '';
        if (links) {
            buttons = links.map(link =>
                <a href={link.uri} target="_blank" rel="noopener noreferrer">
                    <Button variant="" color="primary">
                        {link.title}
                    </Button>
                </a>
            );
        }

        return (
            <VerticalTimelineElement
                className={"vertical-timeline-element--work timelineElement"}
                iconStyle={{ background: '#3A7D8C', color: '#fff' }}
                icon={<FontAwesomeIcon icon={this.getIcon()} size="lg" />}
            >
                <Typography variant="h4">
                    {name}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    {date}
                </Typography>
                <img width="100%" src={picture} alt={name} />
                <Typography variant="body1" gutterBottom>
                    {description}
                </Typography>
                {icons ?
                    <div className={classes.logoContainer}>
                        {icons && icons.map(i => <img src={`/media/logos/${i}.png`} height='100%' className={classes.logo} alt={i} />)}
                    </div>
                    : ''}
                <div className={classes.buttonContainer}>
                    {moreInformation ?
                        <Button variant="contained" onClick={() => onClick()} color="primary">
                            {(loadingReadMore) ? 'loading...' : 'Read more'}
                        </Button> : ''}
                    {buttons}
                </div>
            </VerticalTimelineElement>
        );
    }
}

export default withStyles(styles)(TimelineElement);

