import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';

import theme from '../css/theme';
import Index from './HomePage/HomePage';

const styles = theme => ({
  app: {
    background: '#e1e1e1',
  },
});

class App extends Component {

  render() {
    const { classes } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.app}>
          <Index />
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(App);
