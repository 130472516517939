import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CircularProgressbar from 'react-circular-progressbar';

const styles = theme => ({
  container: {
    maxWidth: theme.spacing.unit * 14,
    marginRight: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    display: 'inline-block',
    textAlign: 'center',
  },
});

class Skill extends Component {
  render() {
    const { classes, percentage, name } = this.props;

    return (
      <div className={classes.container}>
        <CircularProgressbar
          initialAnimation
          percentage={percentage}
          text={`${percentage} Points`}
          styles={{
            text: {
              fill: '#4698abff',
              fontSize: 15,
            },
            path: {
              stroke: '#4698abff',
            },
          }}
        />
        <Typography variant="subtitle1" gutterBottom noWrap>{name}</Typography>
      </div>
    );
  }
}

export default withStyles(styles)(Skill);
