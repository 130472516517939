import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {AppBar, Dialog, Grid, IconButton, Slide, Toolbar, Typography} from '@material-ui/core';

import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faStar, faTimes} from '@fortawesome/free-solid-svg-icons'

import ReactMarkdown from 'react-markdown';

import {getSubPage, setSubPage} from '../Helpers/RouterHelper';

import TimelineElement from './components/TimelineElement/TimelineElement';
import TimeLineData from '../../data/timeline';
import CodeBlock from './components/CodeBlock/CodeBlock';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles = theme => ({
    page: {
        overflow: 'hidden',
    },
    detailedInfoContainer: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        maxSize: '100%',
    },
    dialogAppBar: {},
    timeLine: {
        minWidth: '60%',
    }
});

class TimeLine extends Component {
    state = {
        selectedWork: -1,
        loadingWork: -1,
        md: '',
    };

    componentWillMount() {
        const index = getSubPage();

        if (index >= 0) {
            this.setState({loadingWork: TimeLineData[index]});
            this.loadMdFile(TimeLineData[index].md, index);
        }
    }

    handleClose() {
        this.setState({selectedWork: -1});
        setSubPage();
    }

    loadMdFile(md, index) {
        fetch(md).then((response) => {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response.text();

        }).then(text => {
            this.setState({md: text, selectedWork: index, loadingWork: -1});
        }).catch((data) => {
            console.error(data);
        });
    }

    render() {
        const {selectedWork, md, loadingWork,} = this.state;
        const {classes} = this.props;

        const items = TimeLineData.map((i, index) => <TimelineElement
            key={index} name={i.title} icons={i.icons}
            description={i.description}
            picture={i.picture}
            date={i.date}
            type={i.type}
            moreInformation={i.md !== undefined}
            loadingReadMore={loadingWork === index}
            links={i.links}
            onClick={() => {
                setSubPage(index);
                this.setState({loadingWork: TimeLineData[index]});
                this.loadMdFile(TimeLineData[index].md, index);
            }}/>);

        return (
            <div className={classes.page}>
                <Typography variant="h4" align="center">
                    A new adventure
                </Typography>
                <VerticalTimeline className={classes.timeLine}>
                    {items}
                    <VerticalTimelineElement
                        iconStyle={{background: 'rgb(16, 204, 82)', color: '#fff'}}
                        icon={<FontAwesomeIcon icon={faStar} size="lg"/>}
                    />
                </VerticalTimeline>

                <Dialog fullScreen open={selectedWork >= 0} onClose={() => this.handleClose()}
                        TransitionComponent={Transition} onEnter={() => window.PR.prettyPrint()} scroll="paper">
                    <AppBar className={classes.dialogAppBar} position="sticky">
                        <Toolbar>
                            <IconButton color="inherit" onClick={() => this.handleClose()} aria-label="Close">
                                <FontAwesomeIcon icon={faTimes} size="lg"/>
                            </IconButton>
                            <Typography variant="h6" color="inherit">
                                {(selectedWork >= 0) ? TimeLineData[selectedWork].title : ''}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.detailedInfoContainer}>
                        <Grid container spacing={24} justify="center">
                            <Grid item xs={12} sm={6}>
                                <div className="markDown">
                                    <ReactMarkdown source={md} escapeHtml={false} renderers={{code: CodeBlock}}/>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(TimeLine);
