import UnrealTutorial from './md/UnrealTutorial.md';
import TheSeminaryOfSight from './md/TheSeminaryOfSight.md';
import ProceduralRaceWorldGeneration from './md/ProceduralRaceWorldGeneration.md';
import CommonCents from './md/CommonCents.md';
import CustomHandlesCreator from './md/CustomHandlesCreator.md';
import AutomaticPlaytesting from './md/AutomaticPlaytesting.md';
import Beetlebots from './md/Beetlebots.md';
import Marigold from './md/Marigold.md';
import Tetris from './md/Tetris.md';
import NejoRampage from './md/NejoRampage.md';
import Concordea from './md/Concordea.md';
import CaptureTheGuy from './md/CaptureTheGuy.md';
import VRecycle from './md/VRecycle.md';
import ROVR from './md/Rovr.md';
import Artgrow from './md/Artgrow.md';
import EHL from './md/EHL.md';
import Tribus from './md/Tribus.md';
import VirtualGateway from './md/VirtualGateway.md';
import Maginex from './md/Maginex.md';
import ARCreator from './md/ARCreator.md';


export default ([
  {
    "title": "AR experience creator",
    "description": "An African VR customer wanted a tool for marketing companies in their country to create and share AR experiences easily. The front end is entirely typescript with React and MUI. The backend is made with ASP.NET 6. The app was created in Unity, and we used Wikitude for the AR recognition.",
    "picture": "/media/screenshots/ARCreator/0.png",
    "md": ARCreator,
    "date": "2023",
    "type": "tool",
    "icons": ["react", "mui", "azure", "unity"]
  },
  {
    "title": "Maginex 360",
    "description": "Maginex 360 is a tool to create and run VR lessons in classrooms. A teacher uses multiple videos to create something that looks like a story tree. Adding images, text, and multiple-choice questions is also possible. The answer to a multiple-choice question can lead to a different video or a different time in a video.",
    "picture": "/media/screenshots/maginex/0.png",
    "md": Maginex,
    "date": "2022",
    "type": "vr",
    "icons": ["unity"]
  },
  {
    "title": "EHL Tribus",
    "description": "EHL was launching a new product called Tribus. Tribus is a block-click system that can be used to build walls, stairs, BBQs, and many more things for your backyard. EHL wanted a design tool for its customers to design their projects, calculate the price and then make the orders.",
    "picture": "/media/screenshots/tribus/0.png",
    "md": Tribus,
    "date": "2022",
    "type": "tool",
    "icons": ["react", "mui", "unity"]
  },
  {
    "title": "Virtual Gateway",
    "description": "The virtual gateway application is a VR showcase of integrated Dutch solutions. The aim is to stimulate matchmaking and trade missions with foreign companies and governments. The Dutch Delta Works, for example, is a project that cannot be shown physically but a digital VR version can.",
    "picture": "/media/screenshots/virtualGateway/0.jpg",
    "md": VirtualGateway,
    "date": "2021",
    "type": "vr",
    "icons": ["unity"]
  },
  {
    "title": "EHL Web tools",
    "description": "EHL, a german seller of pavings and brick walls, wanted two web tools where customers could calculate how much it would cost and what it looks like. I worked as the developer on this product at VROwl. Made in React, with an asp .net backend.",
    "picture": "/media/screenshots/ehl/0.png",
    "md": EHL,
    "date": "2021",
    "type": "tool",
    "icons": ["react"]
  },
  {
    "title": "Artgrow - Stackoverflow for artist",
    "description": "A hobby project that I am doing with a good friend. Many artists use websites like DeviantArt or Facebook to give and received feedback. They use systems and rules which are a lot of manual work (they hack the comment section). We are building an art platform that is special made for feedback. It is still in development. We use many systems that are also used by StackOverflow like an up and down voting system, rewards, points system and it has a build-in draw over tool",
    "picture": "/media/screenshots/artgrow/0.png",
    "md": Artgrow,
    "date": "2020 - 2021",
    "type": "research",
    "links": []
  },
  {
    "title": "ROVR",
    "description": "ROVR is a communication and visualisation tool for spatial environment design. With this tool, you can generate all of the Netherlands in lod2, make changes and view these in VR, the web viewer, and make presentations. I am one of the main developers of this product, and it is also the biggest project I have ever worked on. With the most technical challenges I ever had to face.",
    "picture": "/media/screenshots/rovr/0.png",
    "md": ROVR,
    "date": "2019 - 2022",
    "type": "tool",
    "links": [{ "title": "ROVR website", "uri": "https://ro-vr.nl" }]
  },
  {
    "title": "Job@VR Owl",
    "description": "Vr Owl is a Utrecht based company who started with custom cardboard VR glasses, but is now specialized in VR applications and experiences. I am working as a Unity Developer with the newest VR and AR technology.",
    "picture": "/media/screenshots/vrowl/0.png",
    "date": "2019 - current",
    "type": "work"
  },
  {
    "title": "VRecycle (Vr)",
    "description": "To pick up VR again, I created this small VR game in Unity. It is very simple, you can grab and throw items around. There are multiple levels, but the goal is the same, recycling waste. I only used native VR support of Unity, and made the grab and throw mechanics with XR Input.",
    "picture": "/media/screenshots/vrecycle/0.png",
    "md": VRecycle,
    "date": "2019",
    "type": "game",
    "icons": ["unity", "vive"]
  },
  {
    "title": "Computer play-testing",
    "description": "For my graduation project, I build a framework where you can let the computer play and test a card game. It will play the game while it measures the effects of all cards in all combinations. This data is stored in a database and with a website you can see the stats. The idea is that a designer can use this as an extra tool to balance a game.",
    "picture": "/media/screenshots/playtesting/2.gif",
    "md": AutomaticPlaytesting,
    "date": "2019",
    "type": "research",
    "icons": ["unity", "nodejs"]
  },
  {
    "title": "The Seminary of Sight",
    "description": "The Seminary of Sight is a story driven horror game inspired by the work of H.P. Lovecraft. This was a group project of 16 people. I worked as a AI (artificial intelligence)  programmer and some game mechanics. This game was shown at the film festival in Amsterdam.",
    "picture": "/media/screenshots/theSeminaryOfSight/0.png",
    "md": TheSeminaryOfSight,
    "date": "2018 - 2019",
    "type": "game",
    "icons": ["unreal"]
  },
  {
    "title": "Tutorial: Portals in Unreal",
    "description": "A Tutorial about how you can add portals to Unreal for the AI while keep using the build in NavMesh and behaviour tree. Originally, we needed this in our game 'The Seminary of Sight' but after a few play tests we discovered that it was too powerful and the player didn't understand it. So, we removed it.",
    "picture": "/media/screenshots/portalsInUnreal/testUsingPortal.gif",
    "md": UnrealTutorial,
    "date": "2018",
    "type": "tutorial",
    "icons": ["unreal"]
  },
  {
    "title": "Beetlebots",
    "description": "The Beetlebots has one purpose in life: Pushing snow away from their base. The problem is that there are 3 bases, so no one can be snow free. Children can help or sabotage the robots with their task. The idea behind this project was to introduce robots to children. I programmed, design and made the electronics.",
    "picture": "/media/screenshots/bots/0.jpg",
    "md": Beetlebots,
    "date": "2018",
    "type": "hardware",
    "icons": ["arduino"]
  },
  {
    "title": "Intern and job@Little Chicken",
    "description": "Started as an intern, but later on I worked there for a few extra months. I have been working as Game Developer in Unity for their upcoming game 'Blaze Revolution'. Here I have created a traffic system, hacking system and worked with my colleges on more mechanics",
    "picture": "/media/screenshots/chicken/0.png",
    "date": "2017 - 2018",
    "type": "work"
  },
  {
    "title": "Marigold",
    "description": "Marigold is a platformer game made in the Unreal Engine with a group of 12 people. It was a technical challenge since we didn't have any experience with Unreal and Perforce. A funny way of learning. I have been working as a Game Developer.",
    "picture": "/media/screenshots/marigold/0.png",
    "md": Marigold,
    "date": "2017",
    "type": "game",
    "icons": ["unreal"]
  },
  {
    "title": "Procedural Race World Generation",
    "description": "In this project I wanted to play with World generation and AI. So I created a racing game where the world and track are different every time you play the game. There are 3 opponents, each with a different play style. The project is created in Unity.",
    "picture": "/media/screenshots/raceWorldGeneration/0.png",
    "md": ProceduralRaceWorldGeneration,
    "date": "2017",
    "type": "research",
    "icons": ["unity"]
  },
  {
    "title": "Common Cents",
    "description": "It is a 4 player asymmetrical game with 2 teams: The Commons who's goal it is to create happiness and the Big Business who's goal it is to earn money. The game has 3 stages, with every stage both teams receive a power up.",
    "picture": "/media/screenshots/commonCent/0.jpg",
    "md": CommonCents,
    "date": "2017",
    "type": "game",
    "icons": ["unity"]
  },
  {
    "title": "Custom Handles creator",
    "description": "In Unity there is a class named Handles. This class enables you to draw Gizmos like arrows or lines in the scene view. This can be useful for debugging or for custom plugins like level editors or waypoint systems. Advanced handles are used like build in handles, but now you can create your own handles with custom meshes like a flag or arrow.",
    "picture": "/media/screenshots/customHandles/0.png",
    "md": CustomHandlesCreator,
    "date": "2016",
    "type": "tool",
    "icons": ["unity"]
  },
  {
    "title": "Capture The Guy",
    "description": "This game is about mind control. You can take over a human body and control them. To do this, you need to point at him for 5 seconds, otherwise you would shoot yourself in the nothingness. This is a multiplayer game (networking) made in Unity",
    "picture": "/media/screenshots/captureTheGuy/1.png",
    "md": CaptureTheGuy,
    "date": "2016",
    "type": "game",
    "icons": ["unity"]
  },
  {
    "title": "Tetris 3d: Code Challange",
    "description": "This was a school exercise where the goal was to create good quality and readable code. We started as a group (all second year developers) and created a C# class which included all code conventions. After that we all started to work on individual projects. I created Tetris 3D.",
    "picture": "/media/screenshots/tetris/0.png",
    "md": Tetris,
    "date": "2016",
    "type": "research",
    "icons": ["unity"]
  },
  {
    "title": "GGJ: Nejo Rampage",
    "description": "In 2016 we participated to the Global Game Jam. The theme of this year was 'Rituals'. The Global Game Jam is a challenge to create a game with a small team (we were with 6 people) using the theme within 48 hours.",
    "picture": "/media/screenshots/nejoRampage/0.jpg",
    "md": NejoRampage,
    "date": "2016",
    "type": "game",
    "icons": ["unity", "ggj"]
  },
  {
    "title": "Concordea",
    "description": "The Russians and Americans are going to release Berlin and the rest of Germany. But there is a problem, Berlin has two mayors. There will be an election to decide who will be the mayor of the united Berlin. The game is a multiplayer game where each player is mayor of one part of Berlin.",
    "picture": "/media/screenshots/concordea/1.jpg",
    "md": Concordea,
    "date": "2015",
    "type": "game",
    "icons": ["unity"]
  },
  {
    "title": "Job@Virtask",
    "description": "Working as a Front-end developer with ReactJS, Webpack, and Electron and I have done some design stuff. I have worked on different projects like Anne4Care, a product to help elderly people to live independently for longer. And currently (2019), I am working on LIV. A product to help people with debts.",
    "picture": "/media/screenshots/virtask/0.png",
    "date": "2015 - 2019",
    "type": "work",
    "icons": ["nodejs", "react"]
  },
  {
    "title": "Internship@Pixel Pixies",
    "description": "Worked as a Game Developer on a new release of Villa Eeckhoorn and the app the CombiShaker. Both made in Unity 3.",
    "picture": "/media/screenshots/pixelpixies/0.png",
    "date": "2014 - 2015",
    "type": "work",
    "icons": ["unity"]
  }
]);
