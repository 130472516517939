import React, { Component } from 'react';
import { Typography} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  subInformation: {
    paddingBottom: theme.spacing.unit * 2,
  },
});

class Experience extends Component {
  render() {
    const { classes, title, text, subTitle } = this.props;

    return (
      <div className={classes.subInformation}>
        <Typography variant="caption">{subTitle}</Typography>
        <Typography variant="h6" noWrap>{title}</Typography>

        <Typography variant="body1" gutterBottom>{text}</Typography>
      </div>
    );
  }
}

export default withStyles(styles)(Experience);
