const pageNames = ['work', 'resume', 'aboutMe'];

export const setPageIndex = (tabIndex) => {
  window.location = `/#${pageNames[tabIndex]}`;
};

export const getPageIndex = () => {
  const url = window.location.toString().split('#')[1];
  if (url !== undefined) {
    console.log(url);
    return pageNames.indexOf(url.split('/')[0]);
  }

  return 0;
};

export const setSubPage = (name) => {
  const tabIndex = getPageIndex();

  if (name === undefined) {
    setPageIndex(tabIndex);
  }else {
    window.location = `/#${pageNames[tabIndex]}/${name}`;
  }
};

export const getSubPage = () => {
  const url = window.location.toString().split('#')[1];
  if (url !== undefined) {
    const list = url.split('/');
    return (list.length >= 2) ? url.split('/')[1] : -1;
  }

  return -1;
};
