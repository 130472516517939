import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3A7D8C',
    },
    secondary: {
      main: '#F2DBAE',
    },

  },
  typography: {
    fontFamily: ['Nunito', 'sans-serif'].join(','),
    fontWeight: '800',
  }
});

export default theme;
